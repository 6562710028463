import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'

export const StoreList = ({ stores }) => {
  return (
    <div className="store-locator-list-container">
      <ul>
        {stores.features.map(({ properties }) => {
          const { category } = properties

          return (
            <li className={"store-list-item pb-4 pt-4 " + category}>
              <Row className="d-flex align-items-center">
                <Col sm={3}>
                <Link to={properties.path}>
                  <label>
                    <FontAwesomeIcon icon={faLocationDot} />
                    {properties.name}
                  </label>
                  </Link>
                </Col>
                <Col sm={5}>
                  <p>{properties.address}</p>
                </Col>
                <Col sm={2}>
                  <a href={`tel:${properties.phone}`}>
                    <label>
                      <FontAwesomeIcon icon={faPhone} /> 
                      {properties.phone}
                    </label>
                  </a>
                </Col>
                <Col className="d-flex justify-content-end" sm={2}>
                  <Link className="button outline" to={properties.path}>
                    Details
                  </Link>
                </Col>
              </Row>
            </li>
          )
        })}
      </ul>
    </div>
  )
}