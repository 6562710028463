import React, { useEffect } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { useStores, Map, MapProvider } from 'react-google-store-locator'
import { StoreList } from './StoreList'
import { StoreSearch } from './StoreSearch'
import { isBrowser } from '/src/util'

import './google_store_locator.scss'

const StoreMarkerContent = ({ name }) => {
  return (
    <>
      {name}
    </>
  )
}

const StoreLocatorContext = () => {
  const { state } = useStores()

  useEffect(() => {
    if (!state.map) {
      return
    }

    if (isBrowser) {
      const infoWindow = new window.google.maps.InfoWindow()

      state.map.data.addListener('click', (event) => {
        const name = event.feature.getProperty('name')
        const position = event.feature.getGeometry().get()
  
        infoWindow.setContent(renderToStaticMarkup(<StoreMarkerContent name={name} />))
        infoWindow.setPosition(position)
        infoWindow.setOptions({pixelOffset: new window.google.maps.Size(0, -40)})
        infoWindow.open(state.map)
      })

      state.map.setCenter(new window.google.maps.LatLng(
        -33.8688,
        151.2093,
      ))
    }
  }, [state.map])

  return (
    <>
      <Map 
        options={{
          libraries: ['places'],
          googleMapsApiKey: 'AIzaSyCNqnueGt-wt6vHqZxrWeocQgJIh0EkmbM'
        }}
        googleMapProps={{
          zoom: 5,
          mapContainerStyle: {
            width: '100%',
            height: '400px'
          }
        }} 
      />
      <StoreSearch />
      <StoreList stores={state.stores} />
    </>
  )
}

export const StoreLocator = ({ stores }) => {
  return (
    <MapProvider stores={stores}>
      <StoreLocatorContext />
    </MapProvider>
  )
}
