import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import { StoreLocator } from '/src/components/store_locator'
import { formatStores } from '/src/components/commerce/store/util'

const StoreLocatorPage = ({ data }) => {
  const { edges } = data.allMcStoreMcStore

  const stores = useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: formatStores(edges)
    }
  }, [])

  return (
    <Layout mainContainer={{fluid: "xxl"}}>
      <Row>
        <Col sm={12} className="p-0">
          <StoreLocator stores={stores} />
        </Col>
      </Row>
    </Layout>
  )
}

export default StoreLocatorPage

export const Head = () => {
  return (
    <>
      <title>Store Locator | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}

export const query = graphql`
{
  allMcStoreMcStore {
    edges {
      node {
        name
        drupal_id
        field_mc_location {
          lat
          lon
        }
        dsap_plant_id
        field_mc_store_opening_times
        field_mc_store_email
        field_mc_store_description
        field_mc_store_delivery_estimate
        field_mc_store_address {
          address_line1
          address_line2
          country_code
          administrative_area
          locality
          postal_code
        }
        field_mc_store_phone_number
        field_mc_store_pickup_estimate
      }
    }
  }
}
`