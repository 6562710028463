import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { SearchInput } from 'react-google-store-locator'
import { Button } from '/src/components/common'

// @todo, pass register into search input as prop
export const StoreSearch = () => {
  return (
    <div className="store-search-container pt-4 pb-4">
      <div className="store-search">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        <SearchInput options={{
          componentRestrictions: {country: "au"}
        }} />
      </div>
      <Button>Search</Button>
    </div>
  )
}
